import React, { useState } from "react"
import Link, { navigate } from "gatsby-link"
import "../styles/pages-style/apply.scss"

const ApplyJob = () => {
	const [values, setValues] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		file: null,
		message: "",
	})

	const encode = data => {
		const formData = new FormData()
	  
		for (const key of Object.keys(data)) {
		  formData.append(key, data[key])
		}
	  
		return formData
	}

	const postForm = () => {
		fetch('/application/submit', {
			method: 'POST',
			body: encode({
			  'form-name': 'Contact Form',
			  ...values,
			}),
		})
		.then(() => navigate('/successfully-submitted'))
		.catch((error) => alert(error))
	}

	const handleSubmit = event => {
		event.preventDefault();

		if (
			values.name.length > 1 &&
			values.email.length > 1 &&
			values.phoneNumber.length > 1 &&
			values.message.length > 1 &&
			values.file
		) {
			return postForm(event.target);
		} else {
			let nameCheck = values.name.length > 0
			let emailCheck = values.email.length > 0
			let numberCheck = values.phoneNumber.length > 0
			let fileCheck = values.file !== null
			let messageCheck = values.message.length > 0
			const errors = {
				name: nameCheck,
				email: emailCheck,
				phoneNumber: numberCheck,
				file: fileCheck,
				message: messageCheck,
			}

			for (const [key, value] of Object.entries(errors)) {
				if (value == false) {
					document.getElementById(key + "Error").style.opacity = "1 "
				} else {
					document.getElementById(key + "Error").style.opacity = "0"
				}
			}
		}
	}

	const handleChange = event => {
		const { name, value } = event.target
		if (name == "file") {
			setValues({
				...values,
				file: event.target.files[0],
			})
		} else {
			setValues({
				...values,
				[name]: value,
			})
		}
		if (
			values.name.length > 1 &&
			values.email.length > 1 &&
			values.phoneNumber.length > 1 &&
			values.message.length > 1 &&
			values.file
		) {
		}
	}
	return (
		<div className="apply bg-black min-h-screen">
			<div className="container">
				<div className="apply-head">
					<div className="container">
						<div className="apply-head__wrap">
							<span className="caption text-white">Apply job</span>
							<Link to="/job" className="text-white">Cancel</Link>
						</div>
					</div>
				</div>
				<div>
					<form
						name="Contact Form"
						method="POST"
						data-netlify="true"
						action="/successfully-submitted"
						id="applyForm"
					>
						<input type="hidden" name="form-name" value="Contact Form" />

						<div className="apply-form">
							<div className="apply-form__col">
								<label>Full Name</label>
								<input
									placeholder="Full Name"
									name="name"
									value={values.name}
									onChange={handleChange}
									onFocus={e => {
										e.target.placeholder = ""
										e.target.previousSibling.style.opacity = "1"
										e.target.previousSibling.style.color = "#FFC20E"
									}}
									onBlur={e => {
										e.target.placeholder = "Full Name"
										if (e.target.value == "") {
											e.target.previousSibling.style.opacity = "0"
										} else {
											e.target.previousSibling.style.color = "white"
										}
									}}
								/>
								<p id="nameError">
									This is a required field
								</p>
							</div>
							<div className="apply-form__col">
								<label>E-mail</label>
								<input
									placeholder="E-mail"
									name="email"
									onChange={handleChange}
									onFocus={e => {
										e.target.placeholder = ""
										e.target.previousSibling.style.opacity = "1"
										e.target.previousSibling.style.color = "#FFC20E"
									}}
									onBlur={e => {
										e.target.placeholder = "E-mail"
										if (e.target.value == "") {
											e.target.previousSibling.style.opacity = "0"
										} else {
											e.target.previousSibling.style.color = "white"
										}
									}}
								/>
								<p id="emailError">
									This is a required field
								</p>
							</div>
							<div className="apply-form__col">
								<label>Number</label>
								<input
									placeholder="Number"
									name="phoneNumber"
									onChange={handleChange}
									onFocus={e => {
										e.target.placeholder = ""
										e.target.previousSibling.style.opacity = "1"
										e.target.previousSibling.style.color = "#FFC20E"
									}}
									onBlur={e => {
										e.target.placeholder = "Number"
										if (e.target.value == "") {
											e.target.previousSibling.style.opacity = "0"
										} else {
											e.target.previousSibling.style.color = "white"
										}
									}}
								/>
								<p id="phoneNumberError">
									This is a required field
								</p>
							</div>
							<div className="apply-form__col">
								<label htmlFor="file-upload" className="upload-input">
									<div>{values.file && values.file.name}</div>
									<span>Upload</span>
								</label>
								<input
									type="file"
									name="file"
									id="file-upload"
									onChange={handleChange}
									placeholder={values.file && values.file.name}
								/>
								<p id="fileError">
									This is a required field
								</p>
							</div>
							<div className="apply-form__col apply-form__col--mess">
								<label>Little bit about yourself</label>
								<textarea
									rows="2"
									name="message"
									onChange={handleChange}
									placeholder="Little bit about yourself"
									onFocus={e => {
										e.target.placeholder = ""
										e.target.previousSibling.style.opacity = "1"
										e.target.previousSibling.style.color = "#FFC20E"
									}}
									onBlur={e => {
										e.target.placeholder = "Little bit about yourself"
										if (e.target.value == "") {
											e.target.previousSibling.style.opacity = "0"
										} else {
											e.target.previousSibling.style.color = "white"
										}
									}}
								></textarea>
								<p id="messageError">
									This is a required field
								</p>
							</div>
						</div>
						<div className="btn" onClick={handleSubmit}>
							Submit Application
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default ApplyJob
